/* customPrimeReactStyles.css */

/* Ajustar estilos generales de la tabla */
.p-datatable .p-datatable-wrapper {
    border: 1px solid rgba(224, 224, 224, 1);
  }
  
  /* Ajustar estilos de la cabecera de la tabla */
  .p-datatable thead tr {
    background-color: rgba(245, 245, 245, 1);
    color: rgba(33, 33, 33, 1);
    font-weight: 500;
  }
  
  /* Ajustar estilos de las filas de la tabla */
  .p-datatable tbody tr {
    color: rgba(33, 33, 33, 1);
  }
  
  /* Ajustar estilos al pasar el cursor sobre una fila */
  .p-datatable tbody tr:hover {
    background-color: rgba(238, 238, 238, 1);
  }
  